import { InfiniteData, QueryClient } from '@tanstack/react-query';

import { deepMerge } from '../helpers/objects';

const PAGE_SIZE = 50;
const MAX_PAGE_SIZE = 500;
const STALE_TIME = 1000 * 2; // 2 seconds

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: STALE_TIME,
    },
  },
});

const onSuccessUpdateSyncItem = <T extends { id: string }>(itemUpdated: T, queryKey: string) => {
  queryClient.setQueryData<T>([queryKey, itemUpdated.id], (prevData) => {
    if (prevData === undefined) return prevData;

    return deepMerge(prevData, itemUpdated);
  });
};

const onSuccessDeleteSyncItem = (queryKey: string[]) => {
  queryClient.setQueryData(queryKey, null);
};

const onSuccessUpdateSyncPagesItem = <T extends { id: string }>(itemUpdated: T, queryKey: string) => {
  queryClient.setQueriesData<InfiniteData<T[], number>>({ queryKey: [queryKey] }, (prevData) => {
    if (prevData === undefined) return prevData;

    const updatedPages = prevData.pages.map((page) => {
      return page.map((item) => (item.id === itemUpdated.id ? deepMerge(item, itemUpdated) : item));
    });

    return { pages: updatedPages, pageParams: prevData.pageParams };
  });
};

const onSuccessUpdateSyncListItem = <T extends { id: string }>(
  itemUpdated: T,
  queryKey: string[],
  onUpdate?: () => void,
) => {
  queryClient.setQueryData(queryKey, (prevData: T[]) => {
    if (prevData === undefined) return prevData;

    const updatedList = [...prevData];
    const updatedItemIdex = updatedList.findIndex((cachedItem) => cachedItem.id === itemUpdated.id);

    if (updatedItemIdex >= 0) {
      updatedList.splice(updatedItemIdex, 1, itemUpdated);
    } else {
      updatedList.push(itemUpdated);
    }
    onUpdate?.();

    return [...updatedList];
  });
};

const onSuccessAddSyncPagesItem = <T extends { id: string }>(newItem: T, queryKey: string) => {
  queryClient.setQueriesData<InfiniteData<T[], number>>({ queryKey: [queryKey] }, (prevData) => {
    if (prevData === undefined) return prevData;

    const lastPageIndex = prevData.pages.length - 1;
    const lastPage = prevData.pages[lastPageIndex];
    const updatedLastPage = [...lastPage, newItem];

    const updatedPages = [...prevData.pages.slice(0, lastPageIndex), updatedLastPage];

    return { pages: updatedPages, pageParams: prevData.pageParams };
  });
};

const onSuccessDeleteSyncPagesItem = <T extends { id: string }>(itemDeleted: T, queryKey: string) => {
  queryClient.setQueriesData<InfiniteData<T[], number>>({ queryKey: [queryKey] }, (prevData) => {
    if (prevData === undefined) return prevData;

    const updatedPages = prevData.pages.map((page) => {
      return page.filter((item) => item.id !== itemDeleted.id);
    });

    return { pages: updatedPages, pageParams: prevData.pageParams };
  });
};

export {
  queryClient,
  onSuccessUpdateSyncItem,
  onSuccessUpdateSyncPagesItem,
  onSuccessDeleteSyncPagesItem,
  onSuccessDeleteSyncItem,
  onSuccessAddSyncPagesItem,
  onSuccessUpdateSyncListItem,
  PAGE_SIZE,
  MAX_PAGE_SIZE,
};
