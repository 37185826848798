import { lazy } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AuthRoute from './components/AuthRoute';
import { FudoAlertBanner } from './components/design-system/FudoAlertBanner';
import FudoBottomSheet from './components/design-system/FudoBottomSheet';
import FudoModal from './components/design-system/FudoModal';
import { FudoSnackbar } from './components/design-system/FudoSnackbar';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const LazyHomePage = lazy(() => import('./pages/HomePage'));
const LazyLoginPage = lazy(() => import('./pages/LoginPage'));

function App() {
  return (
    <BrowserRouter>
      <FudoAlertBanner />
      <FudoSnackbar />
      <FudoBottomSheet />
      <FudoModal />

      <SentryRoutes>
        <Route path="/login" element={<LazyLoginPage />}></Route>

        <Route element={<AuthRoute />}>
          <Route path="/*" element={<LazyHomePage />} />
        </Route>
      </SentryRoutes>
    </BrowserRouter>
  );
}

export default App;
