import React from 'react';
import classNames from 'classnames';

import { useFudoModalStore } from '../../store/fudoModalStore';
import Flex from '../Flex';
import FudoDivider from './FudoDivider';
import { FudoIcon } from './FudoIcon';
import FudoText from './typography/FudoText';

const FudoModal: React.FC = () => {
  const {
    fudoModalProps: { content, onClose, size = 'large', title },
    closeModal,
    isOpen,
  } = useFudoModalStore();

  const onCloseModal = () => {
    closeModal();
    onClose?.();
  };

  return (
    <div
      className={classNames('modal', {
        'modal--open': isOpen,
      })}
    >
      <div
        className={classNames('modal__backdrop ', {
          'modal__backdrop--open': isOpen,
        })}
        onClick={onCloseModal}
      />

      <Flex
        direction="column"
        paddingVertical="7"
        paddingHorizontal="8"
        className={classNames('modal__content ', {
          'modal__content--open': isOpen,
          [`modal__content--${size}`]: size,
        })}
      >
        <Flex justify="between">
          {title && (
            <Flex>
              <FudoText size="md" weight="semi-bold">
                {title}
              </FudoText>
            </Flex>
          )}
          <Flex pointer onClick={closeModal}>
            <FudoIcon type="close" size="lg" />
          </Flex>
        </Flex>
        {title && <FudoDivider marginVertical="7" />}
        {content}
      </Flex>
    </div>
  );
};

export default FudoModal;
